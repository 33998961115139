<script setup lang="ts">
import { toRef } from "vue"
import { FindAnAdvisorProfile } from "@/types/profile"
import { useFindAnAdvisorProfile } from "@/composables/advisor-profile"
import Prose from "@/components/shared/Prose.vue"
import Card from "@/components/shared/Card.vue"
import ProfileImage from "@/components/shared/ProfileImage.vue"
import Border from "@/components/shared/Border.vue"

const props = defineProps<{ profile: FindAnAdvisorProfile }>()
const profile = toRef(props, "profile")

const { idealClientsList, profileImageURL } = useFindAnAdvisorProfile(
  props.profile
)
</script>

<template>
  <Card>
    <a :href="`/find-an-advisor/${profile.slug}`" class="group">
      <div class="flex flex-col min-h-full">
        <article class="mb-3">
          <header>
            <div class="flex gap-4">
              <div class="shrink-0 w-24">
                <ProfileImage :src="profileImageURL" />
              </div>

              <div>
                <h1
                  class="text-2xl leading-6 font-extrabold font-display text-xy-black"
                >
                  {{ profile.name }}
                </h1>
                <h2
                  class="text-base leading-tight font-normal text-neutral-600"
                >
                  {{ profile.firmName }}
                </h2>

                <div v-if="profile.designations" class="mt-1.5">
                  <ul class="flex flex-wrap -mx-0.5">
                    <li
                      v-for="designation in profile.designations"
                      :key="designation"
                      class="mx-0.5 mb-0.5"
                    >
                      <span class="xy-badge-blue">{{ designation }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </header>

          <div class="mt-3 space-y-3">
            <div v-if="idealClientsList" class="inline-flex">
              <h3 class="sr-only">Ideal Clients</h3>
              <div>
                <ul class="flex flex-wrap -mx-0.5">
                  <li
                    v-for="client in idealClientsList"
                    :key="client.value"
                    class="mx-0.5 leading-5"
                  >
                    <span
                      class="text-sm font-medium tracking-wide text-neutral-600"
                    >
                      <span class="text-xy-green">&bull;</span>
                      {{ client.value }}
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            <Border size="short" />

            <Prose size="sm">
              <p class="line-clamp-6 text-neutral-700">
                {{ profile.bioShort }}
              </p>
            </Prose>
          </div>
        </article>

        <div class="mt-auto">
          <a
            class="xy-btn-secondary"
            :href="`/find-an-advisor/${profile.slug}`"
          >
            Advisor Profile &rarr;
          </a>
        </div>
      </div>
    </a>
  </Card>
</template>
