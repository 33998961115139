<script setup lang="ts">
import { SearchFindAnAdvisorProfileParams } from "@/types/profile"
import DismissableBadge from "@/components/shared/DismissableBadge.vue"
import { useFilterTags } from "@/composables/filter-tags"

const searchParams = defineModel<SearchFindAnAdvisorProfileParams>("params", {
  required: true,
})

const filterTags = useFilterTags(searchParams, {
  q: null,
  lat: null,
  long: null,
  state: null,
  location: null, // NOTE(spk): since location is displayed in an input in the main search UI, let's ignore it for now.
  niches: (v) => v,
})
</script>

<template>
  <div v-if="filterTags.length > 0" class="mt-5 flex justify-start gap-3">
    <div class="h5 shrink-0">Filters:</div>
    <div class="flex-1 flex flex-wrap gap-x-2.5 gap-y-2.5">
      <DismissableBadge
        v-for="(filter, idx) in filterTags"
        :key="idx"
        :label="filter.label"
        @dismiss="filter.remove"
      />
    </div>
  </div>
</template>
