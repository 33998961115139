<script setup lang="ts">
import {
  computed,
  nextTick,
  onBeforeMount,
  onMounted,
  ref,
  useTemplateRef,
  watch,
} from "vue"
import {
  AdjustmentsIcon as AdjustmentsIcon,
  LocationMarkerIcon as LocationIcon,
  SearchIcon as SearchIcon,
  ExclamationIcon as NoResultsIcon,
  ThumbDownIcon as ErrorIcon,
} from "@heroicons/vue/outline"
import {
  BaseInput,
  InputLabel,
  Paginator,
  Slideover,
  TrailsRespPaged,
  XYSpinner,
  debounceFn,
  useBaseAPIGet,
  useUrlSearchParams,
} from "@xy-planning-network/trees"
import AppLayout from "@/components/find-an-advisor/shared/AppLayout.vue"
import Card from "@/components/shared/Card.vue"
import GrayGridBG from "@/assets/gray-grid.webp"
import ProfileCard from "@/components/find-an-advisor/index/ProfileCard.vue"
import GradientBG from "@/assets/gradient-blueberry.webp"
import {
  FindAnAdvisorProfile,
  SearchFindAnAdvisorProfileParams,
} from "@/types/profile"
import Border from "@/components/shared/Border.vue"
import InitialProps from "@/types/root"
import type { Pagination } from "@xy-planning-network/trees"
import TransitionGroupFadeDown from "@/components/shared/TransitionGroupFadeDown.vue"
import CircleIcon from "@/components/shared/CircleIcon.vue"
import type { Coordinates } from "@/types/location"
import GooglePlacesField from "@/components/shared/GooglePlacesField.vue"
import { usePageMeta } from "@/composables/browser"
import { router } from "@/routes"
import SearchFields from "@/components/find-an-advisor/index/SearchFields.vue"
import FilterTags from "@/components/find-an-advisor/index/FilterTags.vue"

const props = defineProps<{
  initialParams: SearchFindAnAdvisorProfileParams
  initialProps: InitialProps
  niches: Record<string, string[]>
}>()

usePageMeta({
  title: "Fee Only Financial Planning | XYPN Find An Advisor",
  meta: [
    {
      name: "description",
      content:
        "Find your ideal financial advisor. XYPN is the leading network for fee-for-service financial advisors who want to serve next generation clients.",
    },
  ],
})

const { result, error, isLoading, execute, hasFetched } = useBaseAPIGet<
  TrailsRespPaged<FindAnAdvisorProfile>
>(router.FindAdvisors(), {
  skipLoader: true,
  withDelay: 650,
})

const profiles = computed((): FindAnAdvisorProfile[] => {
  if (result.value && result.value.data.items) {
    return result.value.data.items
  }

  return []
})

const pagination = ref<Pagination>({
  page: 1,
  perPage: 12, // keep the grid balanced
  totalItems: 0,
  totalPages: 0,
})

const showFilters = ref(false)

// useUrlSearchParams updates the searchParams values on window.location.search
const searchParams = useUrlSearchParams(props.initialParams)

// debounce the search input field to avoid mutating the query string too early.
const debounceSearchInput = debounceFn((inputVal: string) => {
  searchParams.value.q = inputVal
}, 550)

const coords = ref<Coordinates>({
  lat: searchParams.value.lat || null,
  long: searchParams.value.long || null,
})

// keep the coordinates updated in the browser search params
const updateCoordsParams = (coords: Coordinates) => {
  searchParams.value.lat = coords.lat || null
  searchParams.value.long = coords.long || null
}

const fetchData = () => {
  const params = {
    ...searchParams.value,
    page: pagination.value.page,
    perPage: pagination.value.perPage,

    // NOTE: latitude and longitude values of 0 are valid geographic coordinates, but
    // neither value will not provide a meaningful result in our use case so 0's are set on the coords
    // lat, long key's when a location is not set. (Without strict null checks, setting them to null
    // doesn't offer any additional type protection).  To avoid sending 0,0 coordinates to the server
    // which may view them as valid.
    lat: searchParams.value.lat || null,
    long: searchParams.value.long || null,
  }

  return execute(params).then((r) => {
    pagination.value = {
      page: r.data.page,
      perPage: r.data.perPage,
      totalItems: r.data.totalItems,
      totalPages: r.data.totalPages,
    }
  })
}

// scoll to and focus primary input from "start your search" button
const searchInputRef = useTemplateRef("searchInput")
const startSearch = () => {
  nextTick(() => {
    document.getElementById("search").scrollIntoView({ behavior: "smooth" })
    setTimeout(() => searchInputRef.value.input.focus(), 650)
  })
}

// update search results as inputs change
// NOTE: watching only the search params prevents a race condition where fetchData
// can be called again when pagination is updated from a result
watch(searchParams, fetchData, { deep: true })

// kick off initial search request - marking if initial data is available
onBeforeMount(fetchData)

// send the user straight to the search area if they are returning with a query string
onMounted(() => {
  if (
    searchParams.value.q != "" ||
    searchParams.value.niches?.length > 0 ||
    searchParams.value.location !== ""
  ) {
    startSearch()
  }
})
</script>

<template>
  <AppLayout :on-faa-btn-click="startSearch">
    <!--Header-->
    <div
      class="faa-block py-28 bg-gradient-to-r from-blue-700 to-blue-500 text-white relative"
    >
      <div
        class="bg-scroll bg-cover bg-center absolute inset-0 z-0"
        :style="`background-image: url(${GradientBG});`"
      />
      <div class="container max-w-3xl relative z-10">
        <div class="font-extrabold font-display text-white space-y-6">
          <h1 class="font-extrabold space-y-1">
            <div class="text-base leading-4 uppercase">Find Your</div>
            <div class="text-4xl leading-9 sm:text-5xl xl:text-6xl">
              Ideal Financial Advisor
            </div>
          </h1>
          <h3
            class="font-extrabold text-xl leading-6 sm:text-2xl sm:leading-7 xl:text-3xl 2xl:leading-9 text-gray-50"
          >
            By niche, specialty, or keyword <br />
            Keep it local or connect remotely
          </h3>
        </div>

        <Border size="wide" class="mb-10 mt-8" />

        <div class="flex flex-col gap-6 sm:flex-row sm:items-center">
          <button class="faa-btn-outline-inverse" @click="startSearch">
            Start Your Search
          </button>
        </div>
      </div>
    </div>

    <!--Search Block-->
    <div id="search" class="faa-block pt-16 pb-20 relative">
      <div
        class="bg-neutral-100 bg-scroll bg-center bg-repeat inset-0 absolute opacity-50"
        :style="`background-image: url(${GrayGridBG});`"
        aria-hidden="true"
      />
      <div class="container max-w-screen-xl relative">
        <!-- Search Fields -->
        <div class="mb-12">
          <Card>
            <h3
              class="font-extrabold font-display text-2xl leading-6 md:text-3xl md:leading-7 text-xy-black"
            >
              Start Your Advisor Search
            </h3>
            <Border size="short" class="mt-4 mb-8" />
            <div
              class="grid grid-cols-1 gap-y-3 md:grid-cols-7 md:gap-x-5 xl:gap-x-8 items-center"
            >
              <div class="col-span-3">
                <div class="flex items-center gap-x-2">
                  <div class="shrink-0">
                    <div class="md:hidden">
                      <CircleIcon
                        :icon="SearchIcon"
                        size="small"
                        bg-color="bg-neutral-100"
                        icon-color="text-neutral-500"
                      />
                    </div>
                    <div class="hidden md:block">
                      <CircleIcon
                        :icon="SearchIcon"
                        size="normal"
                        bg-color="bg-neutral-100"
                        icon-color="text-neutral-500"
                      />
                    </div>
                  </div>
                  <div class="flex-1">
                    <InputLabel
                      id="search-label"
                      class="sr-only"
                      for="search"
                      label="Find an Advisor"
                    />

                    <BaseInput
                      id="search-input"
                      ref="searchInput"
                      :model-value="searchParams.q"
                      type="text"
                      placeholder="Search for an Advisor"
                      aria-labelledby="search-label"
                      @update:model-value="debounceSearchInput"
                    />
                  </div>
                </div>
              </div>
              <div class="col-span-3">
                <div class="flex items-center gap-x-2">
                  <div class="shrink-0">
                    <div class="md:hidden">
                      <CircleIcon
                        :icon="LocationIcon"
                        size="small"
                        bg-color="bg-neutral-100"
                        icon-color="text-neutral-500"
                      />
                    </div>
                    <div class="hidden md:block">
                      <CircleIcon
                        :icon="LocationIcon"
                        size="normal"
                        bg-color="bg-neutral-100"
                        icon-color="text-neutral-500"
                      />
                    </div>
                  </div>
                  <div class="flex-1">
                    <GooglePlacesField
                      v-model:location="searchParams.location"
                      v-model:state="searchParams.state"
                      v-model:coords="coords"
                      placeholder="Enter a city, state, or zip code (optional)"
                      @update:coords="updateCoordsParams"
                    />
                  </div>
                </div>
              </div>
              <div>
                <button
                  class="xy-btn inline-flex shrink-0 space-x-1 items-center"
                  @click="showFilters = true"
                >
                  <AdjustmentsIcon class="h-5 w-5" />
                  <span>Filters</span>
                </button>
              </div>
            </div>

            <!-- Filter Tags -->
            <FilterTags v-model:params="searchParams" />
          </Card>
        </div>

        <!--Search content-->
        <div class="relative min-h-[18rem]">
          <TransitionGroupFadeDown>
            <!-- Profile list -->
            <div
              v-if="isLoading"
              key="spinner"
              class="absolute z-10 top-0 left-0 w-full h-full flex items-center justify-center"
            >
              <XYSpinner>Loading</XYSpinner>
            </div>

            <!--Error Notice-->
            <div v-else-if="hasFetched && error" key="error">
              <div class="mx-auto max-w-xl">
                <Card>
                  <div
                    class="flex items-center justify-between space-x-5 text-xy-black"
                  >
                    <h4 class="font-display font-extrabold text-xl leading-5">
                      Whoops! Something's gone haywire.
                    </h4>
                    <div class="shrink-0 ml-auto" aria-hidden="true">
                      <CircleIcon
                        :icon="ErrorIcon"
                        bg-color="bg-red-50"
                        icon-color="text-red-400"
                      />
                    </div>
                  </div>
                  <Border size="short" class="mt-5 mb-6" />
                  <p>
                    This is unusual and we've probably already been alerted to
                    it, but if you continue to see this message please
                    <a
                      href="mailto:membership@xyplanningnetwork.com?subject=Find an Advisor search error"
                      class="xy-link"
                      >let us know</a
                    >.
                  </p>
                </Card>
              </div>
            </div>

            <!--No Matches-->
            <div
              v-else-if="hasFetched && profiles.length === 0"
              key="not-found"
            >
              <div class="mx-auto max-w-xl">
                <Card>
                  <div
                    class="flex items-center justify-between space-x-5 text-xy-black"
                  >
                    <h4 class="font-display font-extrabold text-xl leading-5">
                      We didn't find any Advisors that match your search
                      criteria.
                    </h4>
                    <div class="shrink-0 ml-auto" aria-hidden="true">
                      <CircleIcon
                        :icon="NoResultsIcon"
                        bg-color="bg-orange-50"
                        icon-color="text-orange-400"
                      />
                    </div>
                  </div>
                  <Border size="short" class="mt-5 mb-6" />
                  <p>
                    <b>Pro Tip:</b> Many XYPN Advisors are available to work
                    with clients virtually. Start your search off with a single
                    specialty, niche, or keyword that's important to you and
                    refine the results from there.
                  </p>
                </Card>
              </div>
            </div>

            <!--Profile Cards-->
            <div v-else-if="profiles.length > 0" key="profiles">
              <ul class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6">
                <li
                  v-for="profile in profiles"
                  :key="profile.slug"
                  class="grid grid-cols-1"
                >
                  <ProfileCard :profile="profile" />
                </li>
              </ul>
            </div>

            <!--Pagination-->
            <div
              v-if="hasFetched && !isLoading && profiles.length > 0"
              key="pages"
              class="mt-12"
            >
              <Card>
                <div class="pb-2.5">
                  <Paginator
                    v-model="pagination"
                    @update:model-value="fetchData"
                  />
                </div>
              </Card>
            </div>
          </TransitionGroupFadeDown>
        </div>
      </div>
      <!--search filters slideover used for narrow layouts-->
      <Slideover
        v-model="showFilters"
        header="Narrow Your Search"
        description="Find an advisor who serves you"
        class="z-50"
      >
        <div class="relative h-full flex flex-col">
          <SearchFields v-model:params="searchParams" :niches="niches" />
          <!--Actions-->
          <div
            class="sticky inset-x-0 bottom-0 mt-auto py-6 px-3 bg-white border-t border-gray-100 z-50"
          >
            <div class="flex items-center justify-between gap-x-2 -mx-2">
              <button class="xy-btn flex-1" @click="showFilters = false">
                View
              </button>
              <!-- TODO(dlk): do we want to clear location & search bar searches? -->
              <a class="flex-1 xy-btn-neutral" href="/find-an-advisor">
                Clear All
              </a>
            </div>
          </div>
        </div>
      </Slideover>
    </div>
  </AppLayout>
</template>
